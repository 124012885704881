import React, { Component } from "react"
import Slider from 'react-slick'
import AnSata from '../components/services-images/ansata_image'
import Dacar from '../components/services-images/dacar_image'
import WebSummit from '../components/services-images/websummit_image'
import Wedoit from '../components/services-images/wedoit_image'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import Styled from 'styled-components'

const ClientSlider = Styled.div`
width: 80vw;
margin: auto;
`

class Clients extends Component {
    render() {
        let Settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: false,
            pauseOnHover: false,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 2000,
            dots: false,
            arrows: false,
            cssEase: "linear",
            variableWidth: true,
        };
        return (
            <ClientSlider className="spacers">
                <Slider {...Settings}>
                    <AnSata style={{ width: `30vh` }} />
                    <div style={{ width: `30vh` }}></div>
                    <WebSummit style={{ width: `30vh` }} />
                    <div style={{ width: `30vh` }}></div>
                    <Dacar style={{ width: `30vh` }} />
                    <div style={{ width: `30vh` }}></div>
                    <Wedoit style={{ width: `30vh` }} />
                    <div style={{ width: `30vh` }}></div>
                </Slider>
            </ClientSlider>
        )
    }
}

export default Clients