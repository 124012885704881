import React, { Component } from "react"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import Styled from 'styled-components'


const TestimonialsCarousel = Styled.div`
width: 80vw;
margin: auto;
`
 
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style, display: "block", color: "red"}}
            onClick={onClick}
        />
    );
}

class Testimonials extends Component {
    render() {
        let Settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            dots: true,
            arrows: true,
            focusOnSelect: false,
            nextArrow: <SampleNextArrow />,
        };
        return (
            <TestimonialsCarousel className="spacers" style={{ padding: `10vw` }}>
                <h2 style={{ marginBottom: `1em` }}><b>Testimonials</b></h2>
                <Slider {...Settings}>
                    <div>
                        <h4>DUOPANA: Luciano Kalil - CEO and Founder.</h4>
                        <p>
                            A HAG foi fundamental para a estruturação da empresa fora do país, no acordo e mediação
                            entre os sócios, na criação do deck e pesquisa de mercado internacional, no apoio no board, na apresentação para clientes e fundos no Brasil
                            e no exterior, assim como na venda da empresa. Nos acompanhou em todo o ciclo.
                    </p>
                    </div>
                    <div>
                        <h4>ANSATA: Sandro Luiz Milczewski - Sócio Diretor.</h4>
                        <p>
                            Equipe se motivou bastante, o processo foi dinâmico, acho que todo o processo tem
                            atendido às expectativas e o time está mais confiante e aplicando as técnicas aprendidas! Como tudo na vida, os resultados aparecem em um
                            ritmo diferente da curva de aprendizado, até que os processos estejam completamente internalizados pelas equipes percebemos melhorias
                            graduais, os times já estão usando muito bem as ferramentas indicadas, mas o momento do mercado ainda não está reagindo de acordo com
                            as expectativas, mesmo assim estamos todos otimistas e aplicando o que foi aprendido!
                    </p>
                    </div>
                    <div>
                        <h4>WEDOIT: Felippe Siqueira - COO.</h4>
                        <p>
                            Contratamos a HAG no estágio inicial da nossa empresa, para avaliar a possibilidade de
                            internacionalização da WEDOIT. O trabalho foi conduzido de forma profissional, com feedbacks construtivos e ao final criamos, por
                            sugestão da HAG, um processo simplificado de internacionalização que estamos começando a testar. A HAG possui um corpo técnico
                            multidisciplinar com muitos conhecimentos que ajudaram a melhorar o nosso negócio.
                    </p>
                    </div>
                    <div>
                        <h4>FAE: Claudia Machado - Coordenadora de Pós Graduação.</h4>
                        <p>
                            De forma colaborativa, conseguimos perceber a dor do outro (colega,
                            departamento), bem como e, principalmente, a dor do nosso mercado e isso ajudou a elaborar uma solução endereçada a todos, por meio da
                            condução teórica e prática da HAG. Ao final, a solução entregue pela HAG (trabalhada de forma coletiva) foi de altíssima qualidade.
                            Parabéns, a toda a equipe pela condução desse Sprint.
                    </p>
                    </div>
                    <div>
                        <h4>WEBSUMMIT ON THE ROAD: Susana Pereira - Vice-cônsul de Portugal em Curitiba.</h4>
                        <p>
                            Foi um enorme prazer participar no "Web Summit
                            on the Road Curitiba", em setembro de 2019 e verificar o consenso em torno da qualidade e dinamismo do ecossistema de inovação em
                            Portugal. A organização conseguiu reunir oradores e participantes conhecedores e interessados. O debate permitiu realçar uma vez mais a
                            oportunidade que a Web Summit, que Portugal acolhe desde 2016, oferece aos jovens empreendedores em busca de alavancar os seus
                            projetos. Em Portugal e a partir de Portugal, tanto eles como todas as empresas brasileiras podem usufruir de excelentes oportunidades,
                            incluindo o acesso ao vasto mercado da União Europeia.
                    </p>
                    </div>
                    <div>
                        <h4>DACAR: Paulo Roberto Moreira - Diretor Executivo.</h4>
                        <p>
                            Possibilitou uma visão ampla das ferramentas e sua aplicabilidade aos interesses de quem
                            pretende entrar neste ambiente digital.
                            Bruno Barbosa -Gestor novos projetos. Proporcionou uma visão sobre as boas práticas do marketing digital e apresentou ferramentas
                            auxiliares que eram desconhecidas por nós, possibilitando, assim, criar novas perspectivas de relacionamento e negócios com todos os pares
                            que de alguma forma interagem com a nossa empresa/marca.
                    </p>
                    </div>
                </Slider>
            </TestimonialsCarousel>
        )
    }
}

export default Testimonials