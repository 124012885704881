import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col, Card } from "react-bootstrap"
import Styled from "styled-components"

const ImageContainer = Styled.div`
overflow: hidden;
height: 100%;
border-top-right-radius: 4px;
border-top-left-radius: 4px;
img{
    height: 12em;
    border-radius: 0;
    margin: 0;
    object-fit:cover;
}
`

const CardLink = Styled.a`
img{
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    -webkit-transform: scale(1);
	transform: scale(1);
}
&:hover img{
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
`

const RSS = Styled.div`
padding: 10vw;
.card{
    min-height: 22em;
    background-color: black;
    border: 1px solid #ddd;
}
`

const RSSTbc = () => {
    const data = useStaticQuery(graphql`
    {
        allFeedComunidadeTbc(sort: {fields: isoDate, order: DESC}, limit: 3) {
            nodes {
              pubDate
              title
              link
              enclosure {
                url
              }
            }
        }
        feedComunidadeTbcMeta {
            description
        }
    }
`)

    function Indexes({ title }) {
        let string = '';
        for (let i = 0; i <= 55; i++) {
            if (title[i]) {
                string = string.concat(title[i])
                if (i === 55 && title[i]) {
                    string = string.concat('...')
                }
            }
        }
        return string
    }

    return <RSS className="spacers">
        <h2 style={{ marginBottom: `1em` }}><b>COMUNIDADE THE BUSINESS CHANGE</b></h2>
        <p style={{ marginBottom: `3em` }}>{data.feedComunidadeTbcMeta.description}</p>
        <Row>
            <Col sm="12" md="4">
                <CardLink href={data.allFeedComunidadeTbc.nodes[0].link} target="blank">
                    <Card>
                        <ImageContainer>
                            <Card.Img src={data.allFeedComunidadeTbc.nodes[0].enclosure.url} />
                        </ImageContainer>
                        <Card.Body>
                            <Card.Title>
                                <Indexes title={data.allFeedComunidadeTbc.nodes[0].title} />
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </CardLink>
            </Col>
            <Col sm="12" md="4">
                <CardLink href={data.allFeedComunidadeTbc.nodes[1].link} target="blank">
                    <Card>
                        <ImageContainer>
                            <Card.Img src={data.allFeedComunidadeTbc.nodes[1].enclosure.url} />
                        </ImageContainer>
                        <Card.Body>
                            <Card.Title>
                                <Indexes title={data.allFeedComunidadeTbc.nodes[1].title} />
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </CardLink>
            </Col>
            <Col sm="12" md="4">
                <CardLink href={data.allFeedComunidadeTbc.nodes[2].link} target="blank">
                    <Card>
                        <ImageContainer>
                            <Card.Img src={data.allFeedComunidadeTbc.nodes[2].enclosure.url} />
                        </ImageContainer>
                        <Card.Body>
                            <Card.Title>
                                <Indexes title={data.allFeedComunidadeTbc.nodes[2].title} />
                            </Card.Title>
                        </Card.Body>
                    </Card>
                </CardLink>
            </Col>
        </Row>
    </RSS>
}

export default RSSTbc