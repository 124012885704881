import React from 'react'
import Lottie from "lottie-react"
import lottieJson from '../../lottie/33634-compass-animation-3.json'

const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.2],
      type: "stop",
      frames: [0],
    },
    {
      visibility: [0.2, 0.45],
      type: "seek",
      frames: [0, 100],
    },
  ],
}

const Compass = () => {
  return (
      <Lottie
      animationData={lottieJson}
      style={{ height: 300 }}
      interactivity={interactivity}
      />
  )
}

export default Compass