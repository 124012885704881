import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const WebSummitImage = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
      ansataImage: file(relativePath: { eq: "services/websummit.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.ansataImage.childImageSharp.fluid } alt="WebSummit"
    />
}

export default WebSummitImage