import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const DacarImage = ({ style }) => {
  const data = useStaticQuery(graphql`
    query {
        dacarImage: file(relativePath: { eq: "services/dacar.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  return <Img style={style}
    fluid={data.dacarImage.childImageSharp.fluid} alt="Dacar"
  />
}

export default DacarImage