import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ServicesFramework = ({ style }) => {
    const data = useStaticQuery(graphql `
    query {
          servicesFramework: file(relativePath: { eq: "services/services_framework.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
    return <Img style = { style }
    fluid = { data.servicesFramework.childImageSharp.fluid } alt="HAG Business Transformation"
    />
}

export default ServicesFramework