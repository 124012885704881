import React from "react"
import Layout from "../components/layout"
import Menu from "../components/menu"
import { Row, Col } from "react-bootstrap"
import Styled from "styled-components"
import VerticalTitle from "../components/verticaltitle"
import Body from "../components/body"
import BorderedTitle from "../components/bordered_title"
import Testimonials from "../components/testimonials"
import ServicesFramework from "../components/services-images/services_framework"
import Framework from "../components/framework"
import Form from "../components/form"
import Clients from "../components/clients"
import RSSTbc from "../components/rss_tbc"
import Highlight from "../components/highlight"
import Compass from "../components/lottie-components/compass"
import Footer from "../components/footer"
import Office from "../components/services-images/office"
import Sunset from "../components/services-images/sunset"
import Connections from "../components/services-images/connections"
import Canal from "../components/services-images/canal"
import LazyLoad from "react-lazyload"

const BusinessDesign = Styled.div`
background-color: #485696;
padding: 10vw;
min-height: 100vh;
`

const LittleText = Styled.p`
font-size: 0.7em;
`

const IndexPage = () => (
  <Layout style={{ backgroundColor: `black`, color: `#ddd` }}
    lang="en" title="HAG Services" link="https://www.hag.services" description="HAG Services is a boutique consulting firm that focus on business and digital transformation, innovation, corporate venture and impact measurement.">
    <Menu
      MenuItem2={
        <Body>
          <Highlight>
            <Row>
              <Col sm="12" md="2">
                <VerticalTitle style={{ WebkitTextFillColor: `#485696` }}>
                  services
            </VerticalTitle>
              </Col>
              <Col sm="12" md="10" style={{ padding: `10vw` }}>
                <h2><b>Today’s market is continually evolving.</b></h2>
                <p>And here at HAG, we believe that this is the way it should be,
                  whether it’s new technologies and processes or people themselves that drive this <span className="text-highlight-purple">continuous progress.</span></p>
              </Col>
            </Row>
            <Office>
              <div style={{ padding: `10vw` }}>
                <p>We believe in the potential of <b>business transformation</b> through design  and a people-centred approach.We believe in the potential of  business transformation through design  and a people-centred approach.</p>
                <p>Our aim is to help corporates, SMEs and startups become an active part of the  ever-evolving market by designing products, services and business models that create strategic value both for the company and their customers.</p>
              </div>
            </Office>
            <div style={{ padding: `10vw`, textAlign: `center` }}>
              <BorderedTitle>
                <h2 style={{ marginBottom: `1em` }}>
                  <b>TRANFORMING YOUR COMPANY WITH <span style={{ color: `#485696` }}>HAG</span></b>
                </h2>
              </BorderedTitle>
              <h3 style={{ marginTop: `1em`, marginBottom: `3em` }}>The services we offer centre on four key points:</h3>
              <Row className="align-items-center">
                <Col md="2" sm="12" style={{ fontSize: `6em`, color: `#485696` }}>1</Col>
                <Col md="4" sm="12">
                  <p>Making evidence-based decisions through experimentation.</p>
                </Col>
                <Col md="2" sm="12" style={{ fontSize: `6em`, color: `#485696` }}>2</Col>
                <Col md="4" sm="12" >
                  <p>Accomplishing quick and practical work with multidisciplinary teams.</p>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md="2" sm="12" style={{ fontSize: `6em`, color: `#485696` }}>3</Col>
                <Col md="4" sm="12">
                  <p>Focusing on solution viability.</p>
                </Col>
                <Col md="2" sm="12" style={{ fontSize: `6em`, color: `#485696` }}>4</Col>
                <Col md="4" sm="12">
                  <p>Providing companies with the tools, guides and ideas they need to improve the way they innovate.</p>
                </Col>
              </Row>
              <h4>Here’s a list of our services and how your company can benefit from each one.</h4>
            </div>
            <BusinessDesign>
              <h2><b>BUSINESS DESIGN</b></h2>
              <LittleText>Put people at the centre of your approach.</LittleText>
              <p><span className="text-highlight-black">Business design</span> is at the core of our offerings and approach. We put people at the centre when we think of ways to innovate.</p>
              <p>Sympathise, idealise, prototype and validate. This is how we’ll help you develop, test and drive new business ideas.</p>
            </BusinessDesign>
            <Sunset>
              <div style={{ padding: `10vw` }}>
                <p>
                  We combine our proprietary methodology with market methodologies for a fast, intensive and highly structured innovation cycle.
                </p>
                <p>
                  We build multidisciplinary squads to come up with solutions that leverage on the main strengths of your organisation.
                </p>
                <p>With our structured startup approach, you can introduce new products or services to the market and test them – all in <b>less than a week.</b></p>
              </div>
            </Sunset>
            <div style={{ padding: `10vw` }}>
              <h2>
                <b>BUSINESS ACCELERATOR</b>
              </h2>
              <LittleText>Achieve sustainability with speed and agility.</LittleText>
              <p>We’ll provide your startup or spin-off company with the support it needs to become completely self-sufficient.</p>
              <p>With HAG’s streamlined and agile methodologies, you can test new business ideas and develop sustainable business models in a <span className="text-highlight-purple">collaborative</span> learning environment.</p>
            </div>
            <div style={{ backgroundColor: `#485696`, padding: `10vw`, minHeight: `100vh` }}>
              <h2>
                <b>STRATEGIC SPRINT</b>
              </h2>
              <LittleText style={{ color: `black` }}>Develop a clear action plan for your growth.</LittleText>
              <Row>
                <Col sm="12" md="6">
                  <p>Through our fast and intensive <b>innovation</b> strategy sprint, we can help you develop a growth-oriented action plan in less than a week.</p>
                </Col>
                <Col sm="12" md="6">
                  <p>Our innovation strategy specialists will work closely with your company to define the right priorities, create the innovation metrics to follow your progress, and develop a clear action plan with everyone’s involvement.</p>
                </Col>
              </Row>
            </div>
            <div style={{ padding: `10vw` }}>
              <h2>
                <b>BUSINESS TRANSFORMATION</b>
              </h2>
              <LittleText style={{ color: `#485696` }}>Nurture a culture of innovation within your company.</LittleText>
              <p>HAG’s <span className="text-highlight-purple">transformational strategies</span> are grounded in <b>innovation</b>, and we aim to create a culture of it within your organisation.</p>
            </div>
            <Row className="align-items-center">
              <Col md="4" className="d-sm-none d-md-block"><Connections /></Col>
              <Col md="8" sm="12" style={{ padding: `10vw` }}>
                <p>Our ultimate goal for your company is to develop and preserve its capacity to innovate on its own.</p>
                <p>We work to ensure that the learnings you gain from us remain within your company for years to come.</p>
              </Col>
            </Row>
            <div style={{ backgroundColor: `#485696`, padding: `10vw`, minHeight: `100vh` }}>
              <h2 style={{ color: `black` }}><b>EXPAND YOUR BUSINESS WITH US</b></h2>
              <p>Whether you wish to scale your business, achieve sustainability, innovate or <span className="text-highlight-black">go international</span>, HAG can assist you with its customised programs.</p>
              <Row className="align-items-center">
                <Col md="6" sm="12">
                  <p>We tailor our approach to your company values and business objectives to ensure that your goals are met.</p>
                </Col>
                <Col md="6" sm="12">
                  <LazyLoad offset={100}>
                    <Compass />
                  </LazyLoad>
                </Col>
              </Row>
            </div>
            <Canal>
              <div style={{ padding: `10vw` }}>
                <p>And if the solutions mentioned above don’t fit your company’s specific needs, we will apply our broad experience in the international market to build and customise a solution for you.</p>
              </div>
            </Canal>
            <div style={{ padding: `10vw` }}>
              <p>Our consulting services in <span className="text-highlight-purple">venture building</span>, <b>business and digital transformation, innovation and business design</b> are yours to take full advantage of. Let us be your companion in innovation and growth. Contact us today.</p>
            </div>
            <LazyLoad offset={100} height={`100vh`}>
              <Framework
                image={<ServicesFramework />}
              >
              </Framework>
            </LazyLoad>
            <div style={{ backgroundColor: `#485696`, paddingTop: `5vw`, paddingBottom: `1vw` }}>
              <div style={{ paddingLeft: `10vw` }}>
                <h2><b>HAG's CASES</b></h2>
                <p>Companies that trusted in our expertise</p>
              </div>
              <LazyLoad offset={100} height={`20vh`}>
                <Clients />
              </LazyLoad>
            </div>
            <Testimonials />
            <RSSTbc />
            <Form />
            <Footer />
          </Highlight>
        </Body>
      }
      Titles={["about", "studio", "academy"]}
      Links={["https://www.hag.ventures", "https://www.hag.ventures/about", "https://www.hag.studio", "https://www.hag.academy"]}
    >
    </Menu>
  </Layout >
)

export default IndexPage
